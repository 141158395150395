import MobileHeader from '@src/components/MobileHeader';
import PlayLayout from '@src/components/PlayLayout';
import LeftIcon from '@assets/icons/leading-icn.svg';
import RefreshIcon from '@assets/icons/refresh.svg';
import MicIcon from '@assets/icons/mic-filled.svg';
import LockIcon from '@assets/icons/lock-filled.svg';
import GamepadIcon from '@assets/icons/gamepad.svg';
import PeopleIcon from '@assets/icons/people.svg';
import CrownIcon from '@assets/icons/crown.svg';
import DefaultProfileImage from '@assets/icons/avatar.svg';
import EmptyDagoo from '@assets/images/empty-list.png';
import { styled } from 'stitches.config';
import { navigate } from 'gatsby';
import { roomStatusToLabel } from '@src/constants/playroom';
import { useAgora } from '@src/hooks/useAgora';
import { useGetRoomsQuery } from '@src/apis/queries';
import {
  RoomJoinedUserResponseDto,
  RoomResponseDtoStatusEnum,
} from '@uniquegood/realworld-play-interface/dist';
import {
  usePostExitRoomMutation,
  usePostJoinRoomMutation,
} from '@src/apis/mutation';
import { usePlayroomContext } from '@src/providers/Playroom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal, { useModal } from '@src/components/Modal';
import { CheckPasswordModal } from '@src/components/Modal/play/CheckPasswordModal';
import { ConfirmLeaveModal } from '@src/components/Modal/play/ConfirmLeaveModal';
import { getLocalStorage } from '@src/utils/localStorage';
import { CLIENT_ID, LOGIN_PAGE_HOST } from '@src/apis';
import { useQueryString } from '@src/hooks/useQueryString';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import { useInView } from 'react-cool-inview';
import { useToastContext } from '@src/providers/Toast';
import { GeneralHead } from '@src/components/GeneralHead';
import { ErrorModal } from '@src/components/Modal/play/ErrorModal';

export default function PlayRooms() {
  const { isPreparing } = useQueryString();

  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(getLocalStorage('accessToken'))
  );

  const {
    agoraContextData: { handleJoinOrLeave, isConnected, channel },
  } = useAgora();

  const { openSheet, currentRoomData } = usePlayroomContext();
  const { showToast } = useToastContext();

  const {
    data,
    refetch: refetchRooms,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetRoomsQuery();
  const isEmpty = data?.pages.map((page) => page?.content).flat().length === 0;

  const { mutateAsync: joinRoom } = usePostJoinRoomMutation();
  const { mutateAsync: exitRoom } = usePostExitRoomMutation();

  const { openModal, closeModal, modal } = useModal();

  const { observe } = useInView({
    onEnter: ({ unobserve }) => {
      if (!isFetching) {
        unobserve();

        fetchNextPage();
      }
    },
  });

  const checkAuth = (): boolean => {
    const loginUrl = `https://${LOGIN_PAGE_HOST}/ui/auth/oauth?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${encodeURI(
      `${window.location.href}`
    )}`;

    if (!isLoggedIn) {
      window.location.href = loginUrl;
      return false;
    } else return true;
  };

  const handlePrevClick = () => {
    navigate(`/projects`);
  };

  const handleCreateClick = () => {
    if (!checkAuth()) return;

    if (isConnected) {
      openModal(
        <ConfirmLeaveModal
          onClose={closeModal}
          onConfirm={() => {
            handleJoinOrLeave();

            navigate('create');
          }}
          isCreate
        />
      );

      return;
    }

    navigate('create');
  };

  const handelEmptyCreateRoom = () => {
    if (!checkAuth()) return;

    navigate('create');
  };

  const handleRoomClick = async (
    roomId: string,
    isPrivateRoom: boolean,
    isClosed: boolean
  ) => {
    if (!checkAuth()) return;

    if (isClosed) {
      showToast('이미 종료된 플레이룸이에요.');
      return;
    }

    // 이미 방에 들어가 있을 때
    if (isConnected) {
      // 클릭한 방과 현재 들어가있는 방이 같을 때
      if (roomId === currentRoomData?.id) {
        openSheet();
        return;
      }
      // 클릭한 방과 현재 들어가있는 방이 다를 때
      openModal(
        <ConfirmLeaveModal
          onClose={closeModal}
          onConfirm={async () => {
            handleJoinOrLeave();

            closeModal();

            setTimeout(async () => {
              if (isPrivateRoom) {
                openModal(
                  <CheckPasswordModal roomId={roomId} onClose={closeModal} />
                );
              } else {
                await handleJoin(roomId);
              }
            }, 300);
          }}
        />
      );
    } else {
      // 방에 들어가 있지 않을 때
      if (isPrivateRoom) {
        // 비공개 방일 때
        openModal(<CheckPasswordModal roomId={roomId} onClose={closeModal} />);
      } else {
        // 공개 방일 때
        await handleJoin(roomId);
      }
    }
  };

  const handleJoin = async (roomId: string, password?: string) => {
    if (!checkAuth()) return;

    try {
      const { data } = await joinRoom({ roomId, password });

      if (data?.success) {
        handleJoinOrLeave(roomId);

        openSheet();
      } else {
        await exitRoom();
      }

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorResponse = e.response?.data;

        const errorTitle = errorResponse?.errorTitle;

        switch (errorTitle) {
          case 'LimitRoomUserCount':
            showToast('참여 가능 인원수가 초과되어 입장할 수 없어요.');
            break;
          case 'AlreadyEndedRoom':
            showToast('이미 종료된 플레이룸입니다.');
            break;
          case 'ModelValidationFail':
            // 원래 비밀번호가 없는 방에 비밀번호가 생겼을 때, 비밀번호 객체를 넘기지 않으면 발생하는 오류
            openModal(
              <CheckPasswordModal roomId={roomId} onClose={closeModal} />
            );
            break;
          case 'AlreadyExistUserInRoom':
          case 'AlreadyExistRoomUser':
            openModal(
              <ErrorModal
                onClose={closeModal}
                onConfirm={async () => {
                  await exitRoom();
                }}
              />
            );
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    setIsLoggedIn(Boolean(getLocalStorage('accessToken')));
  }, [isPreparing]);

  return (
    <>
      <PlayLayout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          <MobileHeader
            left={
              <Button type="button" onClick={handlePrevClick}>
                <img src={LeftIcon} alt="뒤로 가기" />
              </Button>
            }
            center={
              <Title>
                플레이룸 찾기<Beta>Beta</Beta>
              </Title>
            }
            right={
              <Button type="button" onClick={() => refetchRooms()}>
                <img src={RefreshIcon} alt="새로고침" />
              </Button>
            }
            customStyles={{ marginBottom: '16px' }}
          />
          <MakeRoomButton onClick={handleCreateClick}>
            <img
              src={MicIcon}
              alt="마이크 아이콘"
              style={{ marginRight: '4px' }}
            />
            <span key={'???'} style={{ verticalAlign: 'middle' }}>
              플레이룸 만들기
            </span>
          </MakeRoomButton>

          <div style={{ flexGrow: 1, flexShrink: 1, overflow: 'auto' }}>
            {isFetching && !isFetchingNextPage ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LoadingSpinner />
              </div>
            ) : isEmpty ? (
              <EmptyContainer>
                <EmptyImageWrapper>
                  <EmptyImage src={EmptyDagoo} alt="빈 리스트 이미지" />
                </EmptyImageWrapper>
                <EmptyTitle>플레이룸이 없어요</EmptyTitle>
                <EmptyContent>
                  입장 가능한 플레이룸이 없어요.
                  <br />
                  지금 바로 플레이룸을 만들어보세요!
                </EmptyContent>
                <EmptyButton onClick={handelEmptyCreateRoom}>
                  플레이룸 만들기
                </EmptyButton>
              </EmptyContainer>
            ) : (
              data?.pages
                .map((item) => item?.content)
                .flat()
                .map(
                  (room, index) =>
                    room && (
                      <div
                        key={room.id}
                        ref={
                          index ===
                          data.pages.map((page) => page?.content).flat()
                            .length -
                            1
                            ? observe
                            : null
                        }
                      >
                        <RoomItem
                          onClick={() =>
                            handleRoomClick(
                              room.id,
                              room.isPrivateRoom,
                              room.status === 'Closed'
                            )
                          }
                          status={room.status}
                          title={room.title}
                          projectName={room.roomProject.name}
                          currentUserCount={room.currentUserCount}
                          totalUserCount={room.maxUserCount}
                          isLocked={room.isPrivateRoom}
                          imageUrl={room.roomProject.imageUrl}
                          users={room.joinedUserList}
                        />
                        <Divider />
                      </div>
                    )
                )
            )}
          </div>
          {/* <AdArea>애드몹 광고 영역</AdArea> */}
        </div>
      </PlayLayout>
      <Modal {...modal} />
    </>
  );
}

interface RoomItemProps {
  status: RoomResponseDtoStatusEnum;
  isLocked?: boolean;
  title?: string;
  projectName?: string;
  currentUserCount?: number;
  totalUserCount?: number;
  users?: RoomJoinedUserResponseDto[];
  imageUrl?: string;
  onClick?: () => void;
}

function RoomItem({
  isLocked = false,
  status,
  title,
  projectName,
  currentUserCount,
  totalUserCount,
  users,
  imageUrl,
  onClick,
}: RoomItemProps) {
  return (
    <RoomItemContainer
      onClick={onClick}
      style={{ color: status === 'Closed' ? '#a7a8a9' : '#191919' }}
    >
      <div style={{ width: 'calc(100% - 70px)' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <StatusBadge status={status}>{roomStatusToLabel[status]}</StatusBadge>
          {isLocked && (
            <img
              src={LockIcon}
              alt="잠금 아이콘"
              style={{ marginRight: '4px' }}
            />
          )}
          <RoomItemTitle>{title}</RoomItemTitle>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '4px',
          }}
        >
          <MiniIcon src={GamepadIcon} alt="게임 아이콘" />
          <Text>{projectName}</Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MiniIcon src={PeopleIcon} alt="유저 아이콘" />
          <Text style={{ marginRight: '8px' }}>
            {currentUserCount}/{totalUserCount}
          </Text>
          {status === 'Waiting' && users && users.length > 0 && (
            <>
              <UserThumbnail style={{ marginRight: '4px' }}>
                <UserThumbnailImage
                  src={users[0].profileImageUrl || DefaultProfileImage}
                  alt="유저 이미지"
                />
                {users[0].role === 'Master' && (
                  <img
                    src={CrownIcon}
                    alt="방장 아이콘"
                    style={{ position: 'absolute', top: 0, right: -2 }}
                  />
                )}
              </UserThumbnail>
              <Text>{users[0].name}</Text>
            </>
          )}
        </div>
      </div>
      <ProjectImageWrapper>
        <ProjectImage
          src={imageUrl}
          alt="게임 이미지"
          style={{ opacity: status === 'Closed' ? 0.5 : 1 }}
        />
      </ProjectImageWrapper>
    </RoomItemContainer>
  );
}

const Button = styled('button', {
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const Title = styled('h1', {
  fontSize: '18px',
  fontWeight: 700,
});

const MakeRoomButton = styled('button', {
  width: 'calc(100% - 32px)',
  height: '48px',
  padding: '0 16px',
  margin: '0 16px',
  marginBottom: '24px',
  border: 'none',
  borderRadius: '8px',
  backgroundColor: '#c869ff',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 700,
  cursor: 'pointer',
  boxSizing: 'border-box',
});

const RoomItemContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  position: 'relative',
  width: 'calc(100% - 32px)',
  margin: '0 16px',
  cursor: 'pointer',
});

const RoomItemTitle = styled('div', {
  flexShrink: 1,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '20px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const StatusBadge = styled('span', {
  padding: '3px 6px',
  marginRight: '4px',
  fontSize: '12px',
  fontWeight: 700,
  backgroundColor: '#f9f0ff',
  color: '#c869ff',
  borderRadius: '4px',
  lineHeight: '14px',

  variants: {
    status: {
      Waiting: {
        backgroundColor: '#f9f0ff',
        color: '#c869ff',
      },
      Closed: {
        backgroundColor: '#f2f2f2',
        color: '#8F9092',
      },
      Playing: {
        backgroundColor: '#f2f2f2',
        color: '#8F9092',
      },
      Created: {},
    },
  },
});

const MiniIcon = styled('img', {
  marginRight: '4px',
});

const Text = styled('span', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

const UserThumbnail = styled('div', {
  position: 'relative',
  display: 'inline-block',
  width: '24px',
  height: '24px',
  backgroundColor: '#eee',
  borderRadius: '50%',
});

const UserThumbnailImage = styled('img', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
});

const Divider = styled('div', {
  width: '100%',
  height: '1px',
  margin: '16px 0',
  backgroundColor: '#f2f2f2',
});

const AdArea = styled('div', {
  width: '100%',
  height: '59px',
  backgroundColor: '#e3e3e3',
});

const EmptyImageWrapper = styled('div', {
  position: 'relative',
  width: '160px',
  height: '140px',
  overflow: 'hidden',
});

const EmptyImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const EmptyContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const EmptyTitle = styled('h2', {
  marginBottom: '8px',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '22px',
  color: '#191919',
});

const EmptyContent = styled('div', {
  marginBottom: '24px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#a7a8a9',
  textAlign: 'center',
});

const EmptyButton = styled('button', {
  padding: '15px 16px',
  borderRadius: '999px',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16px',
  border: 'none',
  cursor: 'pointer',
});

const ProjectImageWrapper = styled('div', {
  position: 'relative',
  width: '56px',
  borderRadius: '4px',
  overflow: 'hidden',
});

const ProjectImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const Beta = styled('span', {
  position: 'absolute',
  top: '50%',
  padding: '3px 8px',
  marginLeft: '8px',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '14px',
  color: '#c869ff',
  backgroundColor: '#F9F0FF',
  borderRadius: '999px',
  transform: 'translateY(-50%)',
});

export const Head = () => {
  return (
    <>
      <GeneralHead />
      <title>플레이룸 목록 - 모두의 놀이터, 리얼월드</title>
    </>
  );
};
