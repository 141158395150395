import {
  usePostExitRoomMutation,
  usePostJoinRoomMutation,
} from '@src/apis/mutation';
import AlertIcon from '@assets/icons/exclamation-mark-filled.svg';
import Input from '@src/components/Input';
import { useAgora } from '@src/hooks/useAgora';
import { usePlayroomContext } from '@src/providers/Playroom';
import axios from 'axios';
import React from 'react';
import { styled } from 'stitches.config';
import { BaseModal } from '../../BaseModal';
import { useToastContext } from '@src/providers/Toast';
import { ErrorModal } from '../ErrorModal';
import Modal, { useModal } from '../..';

type CheckPasswordModalProps = {
  roomId: string;
  onClose: () => void;
};

export const CheckPasswordModal = ({
  roomId,
  onClose,
}: CheckPasswordModalProps) => {
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  const {
    agoraContextData: { handleJoinOrLeave },
  } = useAgora();
  const { openSheet } = usePlayroomContext();
  const { showToast } = useToastContext();

  const { mutateAsync: joinRoom } = usePostJoinRoomMutation();
  const { mutateAsync: exitRoom } = usePostExitRoomMutation();

  const { openModal, closeModal, modal } = useModal();

  const handleClick = async () => {
    try {
      const { data } = await joinRoom({ roomId, password });

      if (data?.success) {
        handleJoinOrLeave(roomId);

        openSheet();
      } else {
        await exitRoom();
      }

      onClose();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorResponse = e.response?.data;

        const errorTitle = errorResponse?.errorTitle;

        switch (errorTitle) {
          case 'InvalidRoomPassword':
            setError('비밀번호를 다시 입력해 주세요.');
            break;
          case 'LimitRoomUserCount':
            onClose();
            showToast('참여 가능 인원수가 초과되어 입장할 수 없어요.');
            break;
          case 'AlreadyEndedRoom':
            onClose();
            showToast('이미 종료된 플레이룸입니다.');
            break;
          case 'ModelValidationFail':
            // 원래 비밀번호가 없는 방에 비밀번호가 생겼을 때, 비밀번호 객체를 넘기지 않으면 발생하는 오류
            break;
          case 'AlreadyExistUserInRoom':
          case 'AlreadyExistRoomUser':
            openModal(
              <ErrorModal
                onClose={closeModal}
                onConfirm={async () => {
                  await exitRoom();
                  onClose();
                  closeModal();
                }}
              />
            );
            break;
          default:
            break;
        }

        return e;
      }
    } finally {
      setPassword('');
    }
  };

  const handleClose = () => {
    setPassword('');

    onClose();
  };

  return (
    <>
      <BaseModal onClose={handleClose} title="비밀번호를 입력해주세요">
        <Input
          type="password"
          placeholder="숫자 4자리를 입력해 주세요."
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isError={Boolean(error)}
        />
        {error && (
          <div style={{ width: '100%', marginTop: '8px' }}>
            <ErrorText>
              <img
                src={AlertIcon}
                alt="경고 아이콘"
                style={{ marginRight: '4px' }}
              />
              {error}
            </ErrorText>
          </div>
        )}
        <ControllerContainer>
          <ControlButton
            onClick={handleClose}
            style={{ backgroundColor: '#F2F2F2', color: '#515253' }}
          >
            취소
          </ControlButton>
          <ControlButton
            onClick={handleClick}
            style={{ backgroundColor: '#c869ff', color: '#fff' }}
          >
            입장하기
          </ControlButton>
        </ControllerContainer>
      </BaseModal>
      <Modal {...modal} />
    </>
  );
};

const ControllerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '48px',
  marginTop: '24px',
});

const ControlButton = styled('button', {
  flex: 1,
  height: '100%',
  border: 'none',
  borderRadius: '999px',
  cursor: 'pointer',

  '&:first-of-type': {
    marginRight: '8px',
  },
});

const ErrorText = styled('span', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#F54E54',
});
