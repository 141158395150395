import React from 'react';
import { styled } from 'stitches.config';
import { BaseModal } from '../../BaseModal';

export type ConfirmFn<T> = (value?: T) => Promise<void>;

type ErrorModalProps = {
  onClose: () => void;
  onConfirm: ConfirmFn<string>;
};

export const ErrorModal = ({ onClose, onConfirm }: ErrorModalProps) => {
  return (
    <BaseModal onClose={onClose} title="오류 발생">
      <Content>
        오류가 발생했어요. <br /> 잠시 후 다시 시도해주세요.
      </Content>
      <ControllerContainer>
        <ControlButton
          onClick={() => onConfirm()}
          style={{ backgroundColor: '#c869ff', color: '#fff' }}
        >
          돌아가기
        </ControlButton>
      </ControllerContainer>
    </BaseModal>
  );
};

const ControllerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '48px',
  marginTop: '24px',
});

const ControlButton = styled('button', {
  flex: 1,
  height: '100%',
  border: 'none',
  borderRadius: '999px',
  cursor: 'pointer',

  '&:first-of-type': {
    marginRight: '8px',
  },
});

const Content = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#515253',
  textAlign: 'center',
});
