import { InputHTMLAttributes } from 'react';
import { styled } from 'stitches.config';
import ClearIcon from '@assets/icons/clear-filled.svg';
import React, { forwardRef } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { isError, ...props },
  ref
) {
  const handleClearClick = () => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.value = '';
      ref.current.focus();
    }
  };

  return (
    <Container>
      <StyledInput ref={ref} isError={isError} {...props} />
      {props.value !== undefined && (props.value as string).length > 0 && (
        <ClearButton onClick={handleClearClick}>
          <img
            src={ClearIcon}
            alt="지우기"
            style={{ verticalAlign: 'super' }}
          />
        </ClearButton>
      )}
    </Container>
  );
});

export default Input;

const Container = styled('div', {
  position: 'relative',
  width: '100%',
});

const StyledInput = styled('input', {
  border: '1px solid #ebeced',
  borderRadius: '8px',
  padding: '14px 16px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#333333',
  width: '100%',
  boxSizing: 'border-box',

  '&::placeholder': {
    color: '#C1C2C3',
  },

  variants: {
    isError: {
      true: {
        border: '1px solid #F54E54',
      },
      false: {
        border: '1px solid #ebeced',
      },
    },
  },
});

const ClearButton = styled('button', {
  position: 'absolute',
  top: '50%',
  right: '16px',
  width: '16px',
  height: '16px',
  border: 'none',
  backgroundColor: 'transparent',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
});
